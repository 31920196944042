.navbar-sm{
  display: none;
  margin-right: 20px;
}
.navbar-menu{
  color: #183028
}
.navbar-container{
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent; 
  width: 100%;
  position: absolute;
  padding: 0.5em;
  top: 0;
  z-index: 1;

  .navbar-logo{
    height: 100%;
    padding: 8px;
  }
  .navbar-items{
    margin-right: 15px;
  }
  .navbar-items a{
    font-family: 'aboreto';
    color: #fff;
    text-decoration: none;
    font-size: 1.5em;
    font-weight: 600;
    padding: 0 1em;
    border-left: 1px #000 solid;
    text-shadow: 1px 1px 2px black;
  }
  .ant-modal-header{
    display: none
  }
  .navbar-modal{
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
  }
  .navbar-modal a{
    font-family: 'mplus';
    color: #183028;
    text-decoration: none;
    font-size: 1.5em;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    .navbar-lg{
      display: none
    }
    .navbar-sm{
      display: block
    }
  }
}