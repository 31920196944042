.contenedorF{
  width: 100%;
  min-height: 20vh;
  background-color: #17a786;
  padding: 1em 0;
  .datos{
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white
  }
  .redes{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .redes > div{
    padding: 0 1em;
  }
  .nombre{
    font-size: 1.3em;
    font-weight: bold;
  }
}
