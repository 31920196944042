@font-face {
  font-family: 'mplus';
  src: url('../src/assets/font/MPLUS1-Regular.ttf');
}
@font-face {
  font-family: 'mplusBold';
  src: url('../src/assets/font/MPLUS1-Bold.ttf');
}
@font-face {
  font-family: 'havelock';
  src: url('../src/assets/font/HavelockTitlingBlack.ttf');
}

body {
  margin: 0;
  font-family: 'mplus'
}
html {
  scroll-behavior: smooth;
}
.fondoUnido{
  background-image: url('./assets/img/fondos/fondopag1.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 500px){
  .imgCelular{
    width: 100%;
    height: auto;
  }
}