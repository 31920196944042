.product-container{
  padding-top: 5em;
  background-image: url('../assets/img/fondos/bg-producto2.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 3em;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #17a786 !important; 
  }
  .ant-tabs-ink-bar {
    background: #17a786;
  }
  .bold{
    font-family: "mplusBold";
  }
  .before-after{
    display: flex; 
    justify-content: space-between;
    span{
      font-family: "mplusBold";
      font-size: 1.2em;
      color: #17a786
    }
  }
  .before{
    span{
      font-family: "mplusBold";
      font-size: 1.2em;
      color: #17a786
    }
  }
  .after{
    display: flex;
    justify-content: flex-end;
    span{
      text-align: end;
      font-family: "mplusBold";
      font-size: 1.2em;
      color: #17a786
    }
  }
  .titulo{
    text-align: center;
    font-family: 'Raleway';
    color: #17a786;
    font-size: 2.5em;
    margin-top: 1em;
  }
  .shadow-effect {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 350px;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }

  #customer-testimonoals {
    margin-top: 1em;
  }
  .testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: 250px;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background-color: #17a786;
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #customer-testimonoals .item {
    text-align: center;
    padding: 20px;
    margin-bottom: 0px;
    width: auto;
    opacity: 0.8;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  }
  .bodyFotos{
    background-image: url('../assets/img/fondos/bg.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .divResults{
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: scroll; 
    .results{
      padding: 1em; 
      display: flex; 
      flex-direction: row;
      justify-content: center; 
      align-items: center; 
      margin-bottom: 2em;
      img{
        border-radius: 20px;
      }
    }
    @media (min-width: 1000px){
      justify-content: center;
    } 
  }

  .divResultsMany{
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: scroll; 
    .results{
      padding: 1em; 
      display: flex; 
      flex-direction: row;
      justify-content: center; 
      align-items: center; 
      margin-bottom: 2em;
      img{
        border-radius: 20px;
      }
    }
  }

  .div-center{
    justify-content: center !important;
    @media screen and (max-width: 700px){
      justify-content: flex-start !important;
    }
  }

  .divOnePhoto{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    .results{
      padding: 1em; 
      display: flex; 
      flex-direction: row;
      justify-content: center; 
      align-items: center; 
      margin-bottom: 2em
    } 
  }

  .indicaciones{
    display: flex;
    flex-direction: column;
    align-items: center;
    .texto{
      h2{
        font-family: 'mplus';
        font-size: 2em;
        text-align: center;
      }
      p{
        font-size: 1.3em;
      }
      text-align: left;
      font-size: 1.1em;
    }
  }
  .caracteristicas{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    h2{
      font-family: 'mplus';
      font-size: 2em;
    }
    li, p{
      font-size: 1.1em;
    }
  }
  .divAccion{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-y: scroll; 
    @media (max-width: 500px){
      flex-direction: column;  
    }
    .accion{
      padding: 1em; 
      display: flex; 
      flex-direction: row;
      justify-content: center; 
      align-items: center; 
      margin-bottom: 2em;
      .oxy-accion-title-photo1{
        margin-left: 2em
      }
      .oxy-accion-title-photo2{
        margin-bottom: 1em;
        img{
          @media (max-width: 500px){
            width: 25px !important;  
          }
        }
      }
      img{
        @media (max-width: 500px){
          width: 100%;
        }
      }
    }
    .accionRollaction{
      padding: 1em; 
      display: flex; 
      flex-direction: column;
      justify-content: center; 
      align-items: center; 
      margin-bottom: 2em;
    }  
    .accion2{
      padding: 1em; 
      display: flex; 
      flex-direction: row;
      justify-content: center; 
      align-items: center; 
      margin-bottom: 2em;
      .oxy-accion-title-photo1{
        margin-left: 2em
      }
      .oxy-accion-title-photo2{
        margin-bottom: 1em;
        img{
          @media (max-width: 500px){
            width: 25px;  
          }
        }
      }
    }  
  }
  .listOxy{
    font-size: 1.5em;
  }
  .listOxy li{
    margin-bottom: 1em;
  }
  .divOxy{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .videoOxy{
    width: 600px;
    align-items: center;
    @media (max-width: 600px){
      width: 380px;
    }
  }
}
.text-bold{
  font-family: 'Raleway';
  font-weight: 700;
}