.quienesSomos{
  .header{
    img{
      @media (max-width: 500px){
        height: 350px;
        width: 100%;
        object-fit:cover;
      }
    }
  }
  .titulo{
    text-align: center;
    font-family: 'Raleway';
    color: #17a786;
    font-size: 2.5em;
    margin-top: 1em;
  }
  .shadow-effect {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 350px;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }
  
  #customer-testimonoals {
    margin-top: 1em;
  }
  .testimonial-name {
    margin: -17px auto 0;
    display: table;
    width: 250px;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background-color: #17a786;
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #customer-testimonoals .item {
    text-align: center;
    padding: 20px;
    margin-bottom: 0px;
    width: auto;
    opacity: 0.8;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  }
  .bodyFotos{
    background-image: url('../assets/img/fondos/bg.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .vision{
    margin-bottom: 2em;
    padding: 1em 2em;
    & h2{
      font-family: 'Raleway';
      font-weight: 600;
      font-size: 2em;
    }
    & p{
      font-size: 1.3em;
      text-align: justify;
    }
  }
}