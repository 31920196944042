.contenedor{
  /* background-image: url(../../../public/img/header.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  min-height: 100vh;
  display: flex;
}
.first{
  color: #17a786;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 92vh; */
  text-align: center;
}
.second{
  flex: 1
}
.whatsapp{
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  cursor: pointer;
  z-index: 1;
}
.header-title{
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  margin-left: 2em;
  width: 40%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 25px;
  line-height: 1em;
  text-align: left;
  opacity: 0.35;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.header-subtitle{
  font-size: 20px;
}
.header-title:hover{
  opacity: 1;
}
@keyframes pulse{0%{box-shadow:0 0 0 0 rgba(37,211,102,.5)}80%{box-shadow:0 0 0 14px rgba(37,211,102,0)}}
@keyframes pulse-border{0%{padding:25px;opacity:.75}75%{padding:50px;opacity:0}100%{opacity:0}}
@media screen and (max-width: 767px) {
  .contenedor{
    flex-direction: column;
    background-position: right;
  }
  .first img{
    max-width: 90%;
  }
}