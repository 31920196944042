.contenedorA{
  background-image: url('../assets/img/fondos/fondo2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  .titulo{
    font-size: 2.5em;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color: #17a786;
    text-align: center;
    margin-top: 1em;
    @media (max-width: 500px){
      font-size: 2em
    } 
  }

  .video{
    padding: 0 1em;
    @media (max-width: 500px){
      padding: 0
    } 
  }

  .vision{
    padding: 0 1em;
  }

  .firstA{
    flex: 1
  }
  .firstA img{
    width: 100%
  }
  .secondA{
    flex: 1;
    padding: 1em 2em;
  }
  .secondA h2{
    margin-top: 1em;
    font-size: 2.5em;
    color: #17a786
  }
  .secondA p{
    text-align: justify;
    font-size: 1.3em;
    text-shadow: 1px 1px 2px white;
  }

  @media screen and (max-width: 767px) {
    .contenedorA{
      flex-direction: column;
    }
  }
}
